<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import productionScheduleServices from '../Script/ProductionScheduleServices';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionScheduleCreateGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_form_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: productionScheduleServices.GetProductionScheduleableData(),
                                variables : {
                                    paging : paging,
                                    status : "Ready"
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionPlannings == null){
                            return [];
                        }else{
                            return response.data.GetProductionPlannings.production_plannings;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionPlannings == null){
                            return 0;
                        }else{
                            return response.data.GetProductionPlannings.total;
                        }
                    },
                    model: {
                        fields: {
                            production_planning_date: {type: "proddate"},
                            created_at: {type: "proddate"},
                            quantity: {type: "number"},
                            processing_time_estimation: {type: "number"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { template:this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { field: "production_planning_code", title: "Kode Perencanaan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", title: "Mesin", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "formula_name", title: "Formula", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "base_uom", title: "Satuan Dasar", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "quantity", title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "secondary_uom", title: "Satuan Konversi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= secondary_uom == null ? base_uom : secondary_uom #"},
                { field: "secondary_quantity", title: "Quantity Konversi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= secondary_uom == null ? quantity : secondary_quantity #" },
                { field: "processing_time_estimation", title: "Estimasi (Menit)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "production_planning_date", title: "Jadwal Perencanaan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("change", "#masterCheckBoxFilter", function (e) {
            e.preventDefault();
            var state= this.checked;
            var data = grid._data;
            
            for (var i = 0; i < data.length; i++) {
                var datarow = data[i]
                var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                
                if (elementRow != null) {
                    if (state) {
                    elementRow.checked = true;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                    }
                    else
                    {
                    elementRow.checked = false;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                    }
                }
            }
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
            if (state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="true";
                }
                else if (!state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="false";
                    vueComponent.hf_ID_Filter="";
                }
                else if (state && hf_CheckAll_Filter == "false") {
                    vueComponent.hf_CheckAll_Filter= "true";
                    vueComponent.hf_ID_Filter="";
                }
                else if (!state)
                    vueComponent.hf_CheckAll_Filter="false";
                else
                    vueComponent.hf_CheckAll_Filter= "true";
        });

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.production_planning_id + '|' + row.machine_id;
                            
            var checkedIdArray = hfIDValue.split(',');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
                
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
                
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
                hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
            }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        headerTemplate() {
            return `&nbsp;`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(',');
                
            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.production_planning_id.toString() + '|' + datarow.machine_id;

                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }else{
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    var dataItem = datarow.production_planning_id.toString() + '|' + datarow.machine_id;

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(dataItem) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
    }
}
</script>
    <style scoped>
</style>
