<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Penjadwalan Produksi : {{ProductionScheduleCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Penjadwalan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionScheduleCode" v-model="ProductionScheduleCode" class="font-weight-bold"/>
                            <label id="errorProductionScheduleCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tgl. Penjadwalan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                class="pb-3"
                                v-model="ProductionScheduleDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorProductionScheduleDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="MachineName" v-model="MachineName" class="font-weight-bold" readonly/>
                            <label id="errorMachineName" class="form-error py-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="ProductionDate"
                                format="DD MMM YYYY HH:mm:ss"
                                type="datetime"
                                placeholder=""
                                value-type="YYYY-MM-DD HH:mm:ss"
                                style="width:100%"
                                disabled
                            ></date-picker>
                            <label id="errorProductionDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Estimasi Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="EstimationDate"
                                format="DD MMM YYYY HH:mm:ss"
                                type="datetime"
                                placeholder=""
                                value-type="YYYY-MM-DD HH:mm:ss"
                                style="width:100%"
                                disabled
                            ></date-picker>
                            <label id="errorEstimationDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tgl. Penerimaan Material</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DeliveryDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder=""
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                                disabled
                            ></date-picker>
                            <label id="errorDeliveryDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">                            
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Jadwal Produksi</h4></label>
                            <button class="btn btn-primary float-right" @click="addAdditionalTimeClick()" v-if="this.CurrentStatus == 'New'">
                                <i class="fa fa-plus"></i> Add Additional Time
                            </button>
                            <hr>

                            <datasource ref="ScheduleDataSource" :data="this.ScheduleGridData" :page-size="10" :schema-model-fields="this.ScheduleGridDataSchemaModel"
                                :aggregate="[
                                    { field: 'production_schedule_duration', aggregate: 'sum' },
                                ]"
                            />

                            <kendo-grid ref="gridSchedule"
                                :data-source-ref="'ScheduleDataSource'"
                                :editable="false"
                                :scrollable="true"
                                :pageable="true"
                                :resizable="true"
                                :reorderable="ReorderableValue">

                                <kendo-grid-column  :width="30"
                                                    :draggable="true"></kendo-grid-column>
                                
                                <kendo-grid-column  :width="100"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:center'}"
                                                    :template="columnButton"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'production_schedule_name'"
                                                    :title="'Nama Jadwal'"
                                                    :width="300"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'production_planning_id'"
                                                    :title="'Tipe Jadwal'"
                                                    :width="300"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :template="scheduleType"></kendo-grid-column>

                                <kendo-grid-column  :field="'production_schedule_duration'"
                                                    :title="'Durasi (Menit)'"
                                                    :width="300"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style: 'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="`<div style='float: right'>#= kendo.toString(sum) #</div>`"></kendo-grid-column>

                            </kendo-grid>

                            <additional-time-form ref="additionalTimeForm" :saveAdditionalTimeGrid="saveAdditionalTimeGrid"/>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Total Durasi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="TotalDuration" v-model="TotalDuration" class="font-weight-bold form-control input-number" readonly/>
                        </div> 
                    </CRow>

                    <br>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/production-schedule')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import moment from 'moment';
import productionScheduleServices from '../Script/ProductionScheduleServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';

//Form
import additionalTimeForm from './AdditionalTimeForm.vue';

export default {
    name: 'ProductionPlanningForm',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column' : GridColumn,
        'datasource' : DataSource,
        'additional-time-form' : additionalTimeForm
    },
    async mounted () {
        var grid = this.$refs.gridSchedule.kendoWidget();
        var GridElement = grid.element;
        var vue = this;

        GridElement.on("click", "#UpButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vue.reorderGrid("up", dataItem.uid);
        })

        GridElement.on("click", "#DownButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vue.reorderGrid("down", dataItem.uid);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            grid.dataSource.remove(dataItem);
            vue.sumDuration(true);
        })

        if(this.FormType == 'Add'){
            this.ReorderableValue = {rows: true};
            document.getElementById('ProductionScheduleCode').readOnly = false;
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            
            this.ProductionScheduleId = null;
            this.ProductionScheduleCode = 'Auto';
            this.ProductionScheduleDate = dateNow;

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.CurrentStatus = 'New';

            this.ProductionDate = null;
            this.EstimationDate = null;
            this.DeliveryDate = null;

            var planningData = await productionScheduleServices.getPlanningDetailList(this.PlanningProductionId);
            this.MachineName = planningData.machineName;
            this.MachineId = planningData.machineId;

            //Grid
            this.ScheduleGridData = planningData.planningDetail;
            this.sumDuration(false);
            
            this.SaveType = 'Add';
        } else {
            this.ReorderableValue = {row: true};
            document.getElementById('ProductionScheduleCode').readOnly = true;

            var data = await productionScheduleServices.getProductionScheduleQuery(this.Id);
            
            this.ProductionScheduleId = this.Id;
            this.ProductionScheduleCode = data.production_schedule_code;
            this.ProductionScheduleDate = data.production_schedule_date;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production_schedule');
            this.Status = data.status;
            this.CurrentStatus = data.status;

            if(this.CurrentStatus !== "New"){
                this.ReorderableValue = {rows: false};
            }
            
            this.ProductionDate = data.production_datetime;
            this.EstimationDate = data.estimation_datetime;
            this.DeliveryDate = data.delivery_date;

            var machineData = data.production_schedule_details.filter(item => item.machine_id !== null);
            
            this.MachineName = machineData[0].machine_name;
            this.MachineId = machineData[0].machine_id;

            //Grid
            this.ScheduleGridData = this.$globalfunc.objectToArrayConverter(data.production_schedule_details, "ProductionSchedule-ScheduleGrid")
            this.sumDuration(false);

            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error: 0,

            PlanningProductionId: this.$route.params.id,
            ProductionScheduleId: null,
            ProductionScheduleCode: null,
            ProductionScheduleDate: null,
            StatusData: [],
            Status: null,
            CurrentStatus: null,
            ProductionDate: null,
            EstimationDate: null,
            DeliveryDate: null,
            MachineName: null,
            MachineId: null,
            TotalDuration: 0,

            ReorderableValue: {rows: true},

            // Grid
            ScheduleGridData: [],
            ScheduleGridDataSchemaModel: {
                production_id : { type: "number", editable: false },
                production_planning_id : { type: "number", editable: false },
                production_schedule_id : { type: "number", editable: false },
                production_schedule_name: { type: "string", editable: false },
                production_schedule_duration : { type: "number", editable: false },
            },
        }
    },
    methods: {
        addAdditionalTimeClick(){
            this.$refs.additionalTimeForm.addClick(this.MachineId);
        },
        saveAdditionalTimeGrid(newDataSchedule){
            var grid = this.$refs.gridSchedule.kendoWidget();
            var scheduleData = this.$globalfunc.objectToArrayConverter(grid.dataSource._data, "ProductionSchedule-ScheduleGrid");

            newDataSchedule = newDataSchedule.concat(scheduleData);
            this.ScheduleGridData = newDataSchedule;
            this.sumDuration(false);
        },
        columnButton(e){
            var disabled = '';
            var moveDisabled = '';
            
            if(e.production_planning_id !== null){
                disabled = 'disabled';
            }

            if(this.CurrentStatus !== "New"){
                disabled = 'disabled';
                moveDisabled = 'disabled';
            }
            
            var buttonHtml = `
                <div class="btn-group">
                    <button type="button" class="btn btn-info btn-sm rounded" id="UpButton" ` + moveDisabled + `>
                        <i class="fa fa-arrow-up"></i> </span>
                    </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-primary btn-sm rounded" id="DownButton" ` + moveDisabled + `>
                        <i class="fa fa-arrow-down"></i> </span>
                    </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger btn-sm rounded" id="DeleteButton" ` + disabled + `>
                        <i class="fa fa-trash"></i> </span>
                    </button>
                </div>
            `;

            return buttonHtml
        },
        scheduleType(e){
            var type = "Additional Time";

            if(e.production_planning_id !== null){
                type = "Production Planning";
            }

            return type;
        },
        reorderGrid(type, uid) {
            var gridData = this.$refs.gridSchedule.kendoWidget().dataSource._data;
            var index = gridData.map(function(e) { return e.uid; }).indexOf(uid);
            var toIndex = 0;
            var maxIndex = gridData.length - 1;

            if(type == "up"){
                if(index > 0){
                    toIndex = index - 1;
                }else{
                    toIndex = 0;
                }
            }else{
                if(index >= maxIndex){
                    toIndex = maxIndex;
                }else{
                    toIndex = index + 1;
                }
            }

            var element = gridData[index];
            gridData.splice(index, 1);
            gridData.splice(toIndex, 0, element);
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.ProductionScheduleCode == '' || this.ProductionScheduleCode == null){
                this.errorShow('errorProductionScheduleCode');
            }
            if(this.ProductionScheduleDate == '' || this.ProductionScheduleDate == null){
                this.errorShow('errorProductionScheduleDate');
            }
        },
        sumDuration(onDelete){
            var gridData = this.ScheduleGridData;
            if(onDelete){
                gridData = this.$refs.gridSchedule.kendoWidget().dataSource._data;
            }

            var total = gridData.reduce((n, {production_schedule_duration}) => n + production_schedule_duration, 0);
            this.TotalDuration = total;
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //GridData
                var gridSchedule = this.$refs.gridSchedule.kendoWidget().dataSource._data;
                var gridScheduleData = productionScheduleServices.convertScheduleDetailSave(gridSchedule, this.ProductionScheduleId);

                const productionScheduleData = {
                    production_schedule_id: this.ProductionScheduleId,
                    production_schedule_code: this.ProductionScheduleCode,
                    production_schedule_date: this.ProductionScheduleDate,
                    status: this.Status,
                    delivery_date: this.DeliveryDate,
                    production_datetime: this.ProductionDate,
                    estimation_datetime: this.EstimationDate,
                    additional_time: {},
                    production_schedule_details: gridScheduleData,
                    processed_schedule_details: []
                }
                
                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : productionScheduleData
                    }
                    
                    productionScheduleServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/production-schedule' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : productionScheduleData
                    }
                    
                    productionScheduleServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/production-schedule'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>