import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionScheduleService {
    readProductionScheduleQuery(){
        var query = `
            query ($paging:ServerPaging, $status:[String!]) {
                GetProductionSchedules (Paging:$paging, Statuses:$status) {
                    production_schedules {
                        created_at
                        last_update
                        production_schedule_id
                        production_schedule_code
                        production_schedule_date
                        status
                        delivery_date
                        production_datetime
                        estimation_datetime
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getProductionScheduleQuery(id){
        const variables = {
            id : [id]
        }
        var query = gql`
            query ($id:[Int!]) {
                GetProductionSchedules (ProductionScheduleIDs:$id) {
                    production_schedules {
                        created_at
                        last_update
                        production_schedule_id
                        production_schedule_code
                        production_schedule_date
                        status
                        delivery_date
                        production_datetime
                        estimation_datetime
                        production_schedule_details {
                            created_at
                            last_update
                            production_id
                            production_planning_id
                            production_planning_code
                            production_planning_date
                            production_schedule_id
                            production_schedule_name
                            production_schedule_duration
                            production_schedule_order
                            item_id
                            item_name
                            machine_id
                            machine_name
                            requested_quantity
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProductionSchedules.production_schedules[0];
    }

    GetProductionScheduleableData(){
        var query = ` 
        query ($paging:ServerPaging, $status:[String!]) {
            GetProductionPlannings (Paging:$paging, Statuses:$status) {
                production_plannings {
                    created_at
                    last_update
                    production_planning_id
                    production_planning_code
                    production_planning_date
                    status
                    formula_id
                    formula_name
                    machine_id
                    machine_name
                    production_storage_id
                    production_storage_name
                    item_id
                    item_name
                    base_uom
                    secondary_uom
                    secondary_conv_amount
                    quantity
                    secondary_quantity
                    additional_time
                    processing_time_estimation
                }
                total
            }
        }`;
        return query;
    }

    readAdditionalTimeQuery(){
        var query = ` 
        query ($id:Int) {
            GetMachine (machineID:$id) {
                machine {
                    additional_time_list
                }
            }
        }`;
        return query;
    }

    additionalTimeParseData(data){   
        var additionalTime = globalfunc.destroySingleKendoProperties(data);
        var gridData = [];
        if(additionalTime != null){
            var gridData = Object.keys(additionalTime).map(key => ({
                additional_time_reason: key,
                additional_time_value: additionalTime[key]
            }));
        }     
        return gridData;
    }
    
    additionalTimeObject(data){
        var dataItem = [{
            production_id: null,
            production_planning_id: null,
            production_schedule_id: null,
            production_schedule_name: data.additional_time_reason,
            production_schedule_duration: data.additional_time_value,
        }];
        
        return dataItem;
    }

    validateDuplicateSelectedData(data){
        var array = data.split(',');
    
        let arrayObj = [];
        for (let i = 0; i < array.length; i++) {
            var detailArray = array[i].split('|');
            var obj = {
                production_planning_id: detailArray[0],
                machine_id : detailArray[1]
            }
            arrayObj.push(obj)
        }
        
        var unique = arrayObj
            .map(e => e['machine_id'])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(obj=> arrayObj[obj]);

        if(unique.length > 1){
            return "not match";
        }else{
            var id = arrayObj.map(e => e['production_planning_id']);
            return id;
        }
    }

    async getPlanningDetailList(id){
        var array = JSON.parse(id)
    
        const variables = {
            id : array
        }

        var query = gql`query ($id:[Int!]) {
            GetProductionPlannings (ProductionPlanningIDs:$id) {
                production_plannings {
                    item_name
                    production_planning_code
                    processing_time_estimation
                    production_planning_id
                    machine_name
                    machine_id
                }
            }
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var planningData = {
            planningDetail : [],
            machineName : null,
            machineId : null
        }

        if(result.data.GetProductionPlannings != null){
            var planningDetail = [];
            var data = result.data.GetProductionPlannings.production_plannings;
            
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    production_id: null,
                    production_planning_id: data[i].production_planning_id,
                    production_schedule_id: null,
                    production_schedule_name: "Produksi " + data[i].item_name + " (" + data[i].production_planning_code + ")", 
                    production_schedule_duration: data[i].processing_time_estimation, 
                }
                planningDetail.push(str);
            }

            planningData = {
                planningDetail : planningDetail,
                machineName : data[0].machine_name,
                machineId : data[0].machine_id
            }
        }
        
        return planningData;
    }

    async addQuery(variables){
        var query = gql`
            mutation ( $data:NewProductionSchedule! ) {
                CreateProductionSchedule ( New:$data ){
                    message
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ( $id:Int!, $data:NewProductionSchedule! ) {
                UpdateProductionSchedule ( ID:$id, New:$data ){
                    message
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    convertScheduleDetailSave(data, id){
        var result = [];

        for (let i = 0; i < data.length; i++) {
            var str = { 
                production_id: data[i].production_id, 
                production_planning_id: data[i].production_planning_id,
                production_schedule_id: id,
                production_schedule_name: data[i].production_schedule_name,
                production_schedule_duration: data[i].production_schedule_duration
            }
            result.push(str);
        }

        return result;
    }
}

export default new ProductionScheduleService();