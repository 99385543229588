<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import productionScheduleServices from '../Script/ProductionScheduleServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionScheduleGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['deleteClick','editClick','statusClick'],
    props: ['deleteClick','editClick'],
    data: function () {
        return  {
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: productionScheduleServices.readProductionScheduleQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionSchedules == null){
                            return [];
                        }else{
                            return response.data.GetProductionSchedules.production_schedules;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionSchedules == null){
                            return 0;
                        }else{
                            return response.data.GetProductionSchedules.total;
                        }
                    },
                    model: {
                        fields: {
                            production_schedule_date: {type: "proddate"},
                            production_datetime: {type: "proddate"},
                            estimation_datetime: {type: "proddate"},
                            delivery_date: {type: "proddate"},
                            created_at: {type: "proddate"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "production_schedule_code", title: "Kode Penjadwalan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "production_schedule_date", title: "Tanggal Penjadwalan ", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "production_datetime", title: "Tanggal Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "estimation_datetime", title: "Estimasi Produksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "delivery_date", title: "Tanggal Penerimaan Material", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, filterable: {ui: 'datepicker'} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        // var statusClick = this.$props.statusClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_schedule_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.production_schedule_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.production_schedule_id);
        })

        // //Status Form
        // GridElement.on("click", "#StatusButton", function (e){
        //     e.preventDefault();
        //     var dataItem = grid.dataItem(e.target.closest("tr"));
            
        //     statusClick(dataItem);
        // })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Production Schedule");
        },
        detailInit: async function (e) {
            var data = await productionScheduleServices.getProductionScheduleQuery(e.data.production_schedule_id);

            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "productionScheduleDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data.production_schedule_details);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                    aggregate: [{ field: "production_schedule_duration", aggregate: "sum" }]
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'production_schedule_name', title: "Nama Jadwal", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'production_planning_id', title: "Tipe Jadwal", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true,
                        template: '#= production_planning_id == null ? "Additional Time" : "Production Planning" #' },
                    { field: 'production_schedule_duration', title: "Durasi (Menit)", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, editable: false, nullable: true,
                        footerTemplate: `   <div style='float: right'>#= sum #</div>` },
                ],
            })
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: productionScheduleServices.readProductionScheduleQuery(),
                                variables : {
                                    paging : paging,
                                    status : [status]
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProductionSchedules == null){
                            return [];
                        }else{
                            return response.data.GetProductionSchedules.production_schedules;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProductionSchedules == null){
                            return 0;
                        }else{
                            return response.data.GetProductionSchedules.total;
                        }
                    },
                    model: {
                        fields: {
                            production_schedule_date: {type: "proddate"},
                            production_datetime: {type: "proddate"},
                            estimation_datetime: {type: "proddate"},
                            delivery_date: {type: "proddate"},
                            created_at: {type: "proddate"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            })
        },
    },
}
</script>