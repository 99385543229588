<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Penjadwalan Produksi</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <!-- <production-schedule-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick"/> -->
                <production-schedule-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" />
                <production-schedule-create-form ref="ProductionScheduleCreateForm" :reload="reload"/>
                <production-schedule-status-form ref="ProductionScheduleStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import productionScheduleGrid from './Grid/ProductionScheduleGrid.vue';
import productionScheduleCreateForm from './Component/ProductionScheduleCreateForm.vue';
import productionScheduleStatusForm from './Component/ProductionScheduleStatusForm.vue';
import productionScheduleServices from './Script/ProductionScheduleServices';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'ProductionSchedule',
    components: {
        'production-schedule-grid': productionScheduleGrid, 
        'production-schedule-status-form': productionScheduleStatusForm, 
        'production-schedule-create-form': productionScheduleCreateForm, 
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Production Schedule');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.ProductionScheduleCreateForm.addClick();
        },
        editClick(id, view){
            this.$router.push({ name: 'Production Schedule Form', params: { formtype:'Edit', id:id, view:view } });
        },
        async deleteClick(id){
            var data = await productionScheduleServices.getProductionScheduleQuery(id);

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    this.$loading(true);

                    //GridData
                    var gridScheduleData = productionScheduleServices.convertScheduleDetailSave(data.production_schedule_details, data.production_schedule_id);
                    
                    const productionScheduleData = {
                        production_schedule_id: data.production_schedule_id,
                        production_schedule_code: data.production_schedule_code,
                        production_schedule_date: data.production_schedule_date,
                        status: "Delete",
                        delivery_date: data.delivery_date,
                        production_datetime: data.production_datetime,
                        estimation_datetime: data.estimation_datetime,
                        additional_time: {},
                        production_schedule_details: gridScheduleData,
                        processed_schedule_details: []
                    }
                    
                    const variables = {
                        id : data.production_schedule_id,
                        data : productionScheduleData
                    }
                    
                    productionScheduleServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        // statusClick(data) {
        //     this.$refs.ProductionScheduleStatusForm.statusClick(data);
        // },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReady").on('click', function() {
                    vue.$refs.grid.changeStatus('Ready');
                });
                $("#btnRequestMaterial").on('click', function() {
                    vue.$refs.grid.changeStatus('Request Material');
                });
                $("#btnOnProgress").on('click', function() {
                    vue.$refs.grid.changeStatus('On Progress');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnCancel").on('click', function() {
                    vue.$refs.grid.changeStatus('Cancel');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
    }
}
</script>